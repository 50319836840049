import React from "react";
import * as classes from "./index.module.less";
import Layout from "components/shared/Layout";
import Card from "components/shared/Card";
import startup from "images/svg_icons/startup.svg";
import chart from "images/svg_icons/bar-chart.svg";
import localization from "images/svg_icons/location.svg";
import money from "images/svg_icons/money.svg";

export default () => (
  <div className={classes.container}>
    <Layout>
      <h3>How We Can Help You</h3>
      <div className={classes.cards}>
        <Card
          title="Localization beyond translation."
          href="/services/localization/"
          align="verticalreverse"
          content={
            <p>
              Ensure smooth play with no lag time and full legal compliance when
              we connect you with fast local servers and SDKs that work
              perfectly in China.
            </p>
          }
          img={localization}
        />
        <Card
          title="Maximum distribution."
          href="/blog/how-to-publish-your-game-in-china/"
          align="verticalreverse"
          content={
            <p>
              You'll need a game license (GRN/ISBN), software copyright
              certificate and more, but our team handles all the paperwork and
              approvals for you.
            </p>
          }
          img={startup}
        />
        <Card
          title="Promote across all channels."
          href="/services/acquisition/"
          align="verticalreverse"
          content={
            <p>
              Competition is fierce, but AppInChina will help you pull in
              players through ad campaigns, ASO, social media marketing, and
              more.
            </p>
          }
          img={chart}
        />
        <Card
          title="Monetize the easy way."
          href="/services/monetization/"
          align="verticalreverse"
          content={
            <p>
              Maximize your revenues with in-app ads and payments. You will keep
              more profit by partnering with us than with any other publisher.
            </p>
          }
          img={money}
        />
      </div>
    </Layout>
  </div>
);
