import React from "react";
import { Link } from "gatsby";
import Meta from "components/shared/Meta";
import Nav from "components/shared/Nav";
import Footer from "components/shared/Footer";
import CTA from "components/shared/CTA";
import LargeTitle from "components/services/LargeTitle";
import icon from "images/svg_icons/console.svg";
import { StaticImage } from "gatsby-plugin-image";
import ImageBlock from "components/services/ImageBlock";
import GamesGrid from "components/services/GamesGrid";

export default () => (
  <>
    <Meta url="/services/game-publishing/" />
    <Nav />
    <LargeTitle
      img={icon}
      green="Game Publishing"
      black="Services"
      text="Half of China plays mobile games. We will get them playing yours by taking care of every step - from distribution to promotion to monetization."
    />
    <ImageBlock
      title="1.1 billion potential players"
      text={
        <>
          <p>
            With over $40 billion yearly in gaming revenue - over 60% being
            mobile games - China leads the world in size and speed of mobile
            game adoption. You need to be in this market.
          </p>
          <p>
            But it requires localizing and publishing your game on the Chinese
            Apple App Store and multiple Android app stores, each with their own
            rules - especially difficult if you don't speak Chinese.
          </p>
          <p>
            Welcome to China.{" "}
            <Link to="/blog/how-to-publish-your-game-in-china/">
              We'll take care of the hard stuff for you.
            </Link>
          </p>
        </>
      }
      img={
        <StaticImage
          src="../../images/photos/women-games.jpg"
          alt="Alipay"
          placeholder="blurred"
          layout="fixed"
          width={800}
          height={650}
        />
      }
    />
    <GamesGrid />
    <CTA />
    <Footer />
  </>
);
